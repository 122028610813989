import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import {FaEdit, FaTrash, FaTimes, FaSearch } from 'react-icons/fa'; // Import Bootstrap icons
import './index.css'; 

const API_URL = process.env.REACT_APP_API_URL;
const TOKEN_CHECK_INTERVAL = 60000; // Check token every 60 seconds
const INACTIVITY_LIMIT = 1800000; // 30 minutes inactivity limit

// Custom Modal Component
const CustomModal = ({ show, handleClose, title, body }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Login Component
function Login({ onLoginSuccess }) {
  const [passkey, setPasskey] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/login`, { passkey });
      const { access_token: token } = response.data;
      localStorage.setItem('sessionToken', token);
      onLoginSuccess(); // Notify the parent about successful login
    } catch (err) {
      console.error('Login failed:', err);
      setError('Invalid passkey. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">REACH - Transactional Report Subscription Management</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleLogin} className="border p-4 shadow-sm">
        <div className="row mb-3">
          <div className="col-sm-3">
            <label>Passkey</label>
          </div>
          <div className="col-sm-9">
            <input
              type="password"
              className="form-control"
              value={passkey}
              onChange={(e) => setPasskey(e.target.value)}
              required
              disabled={loading} // Disable when loading
            />
          </div>
        </div>
        <div className="text-center mb-3">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </div>
      </form>
    </div>
  );
}

// Main Application Component
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const [dropdownData, setDropdownData] = useState({
    report_frequencies: [],
    report_types: [],
    subscription_periods: [],
  });

  // State variables
  const [tenantIdSearchTerm, setTenantIdSearchTerm] = useState('');
  const [tenantNameSearchTerm, setTenantNameSearchTerm] = useState('');
  const [emailSearchTerm, setEmailSearchTerm] = useState('');
  const [firstNameSearchTerm, setFirstNameSearchTerm] = useState('');
  const [lastNameSearchTerm, setLastNameSearchTerm] = useState('');
  const [reportTypeSearchTerm, setReportTypeSearchTerm] = useState('');
  const [activeSearchTerm, setActiveSearchTerm] = useState(''); // Search term for active status
  const [subscriptions, setSubscriptions] = useState([]);

  // State variable for email search in modal
  const [modalEmailSearchTerm, setModalEmailSearchTerm] = useState('');
// State variable for selected report types
  const [selectedReportTypes, setSelectedReportTypes] = useState([]);
  
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');

  const handleShowModal = (title, message) => {
    setModalTitle(title);
    setModalBody(message);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const [formData, setFormData] = useState({
    tenant_id: '',
    tenant_name: '',
    emails: '',
    report_frequency: 1, // Default value for report frequency
    report_type: '', 
    subscription_period: 3, // Default value for subscription period
    active: false,
    first_name: '',
    last_name: '',
    user_id: ''
  });

  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // Add separate loading states for adding, updating, and deleting
  const [isAdding, setIsAdding] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState('');
  const [confirmModalTitle, setConfirmModalTitle] = useState('');
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  


  // Handle close modal
  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false); // Close modal without executing the action
  };
  

  const [toast, setToast] = useState({ visible: false, message: '', type: '', header: '' });
  const [showDropdown, setShowDropdown] = useState({
    report_frequency: false,
    report_type: false,
    subscription_period: false,
  });

  // Search Tenant Names
  const [searchTerm, setSearchTerm] = useState('');
  const [tenants, setTenants] = useState([]);
  const [showTenantModal, setShowTenantModal] = useState(false);
  const [loadingTenants, setLoadingTenants] = useState(false);

  // Function to fetch tenants based on tenant ID or search term
  const fetchTenants = async (term, tenantId) => {
    setLoadingTenants(true);
    const token = localStorage.getItem('sessionToken');
    try {
      const response = await axios.get(`${API_URL}/tenants`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          search: term || undefined, // Send search term if it exists
          tenant_id: tenantId || undefined // Send tenant_id if it exists
        },
      });
      setTenants(response.data);
    } catch (error) {
      console.error('Error fetching tenants:', error);
    } finally {
      setLoadingTenants(false);
    }
  };

  // Function to handle report type selection
const handleReportTypeSelect = (event) => {
  const { options } = event.target;
  const selectedValues = [];
  
  for (let i = 0; i < options.length; i++) {
    if (options[i].selected) {
      selectedValues.push(options[i].value);
    }
  }

  setSelectedReportTypes(selectedValues);
};
  // Function to handle tenant search
  const handleSearchClick = (event) => {
    event.preventDefault(); // Prevent default form submission
    const tenant_id = formData.tenant_id; // Get tenant_id from the form
    if (searchTerm) {
      fetchTenants(searchTerm, null); // Fetch tenants based on searchTerm
    } else if (tenant_id) {
      fetchTenants(null, tenant_id); // Fetch tenants based on tenant_id if searchTerm is blank
    } else {
      alert('Please enter a Tenant ID in the form or a search term to search.'); // Alert if both are empty
    }
  };

  const handleTenantSelect = (tenant) => {
    setFormData({ ...formData, tenant_id: tenant.id, tenant_name: tenant.name });
    setShowTenantModal(false);
  };


  const [emails, setEmails] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [loadingEmails, setLoadingEmails] = useState(false);

  const fetchEmails = async (tenantId, term) => {
    setLoadingEmails(true);
    const token = localStorage.getItem('sessionToken');
    try {
      const response = await axios.get(`${API_URL}/emails`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant_id: tenantId, search: term },
      });
      setEmails(response.data);
      
    } catch (error) {
      console.error('Error fetching emails:', error);
    } finally {
      setLoadingEmails(false);
    }
  };

  const handleEmailSearchClick = () => {
    const tenantId = formData.tenant_id;
    fetchEmails(tenantId, emailSearchTerm);
    setShowEmailModal(true);
  };


const handleEmailSelect = (email) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    emails: email.email,  // Update emails
    first_name: email.first_name || '',  // Update first_name if available
    last_name: email.last_name || '' ,   // Update last_name if available
    user_id: email.user_id
  }));
  
  setErrors((prevErrors) => ({ ...prevErrors, emails: '' })); // Clear email error
  setShowEmailModal(false); // Close the modal
};

  const handleReportTypeSearch = (e) => {
    setReportTypeSearchTerm(e.target.value);
  };

  const dropdownRef = useRef(null);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    fetchSubscriptions();
    fetchDropdownData();
  };

  const fetchSubscriptions = useCallback(async () => {
    const token = localStorage.getItem('sessionToken');
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/subscriptions`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSubscriptions(response.data);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDropdownData = useCallback(async () => {
    const token = localStorage.getItem('sessionToken');
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/dropdown-data`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDropdownData(response.data);
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Monitor user activity and refresh token
  useEffect(() => {
    const resetInactivityTimer = () => setLastActivityTime(Date.now());

    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keydown', resetInactivityTimer);
    window.addEventListener('click', resetInactivityTimer);

    const checkInactivityAndToken = setInterval(async () => {
      const now = Date.now();
      const token = localStorage.getItem('sessionToken');

      if (now - lastActivityTime > INACTIVITY_LIMIT) {
        setIsAuthenticated(false);
        localStorage.removeItem('sessionToken');
        handleShowModal('Session Expired', 'You have been logged out due to inactivity');
      } else if (token && now - lastActivityTime < INACTIVITY_LIMIT) {
        try {
          const response = await axios.post(`${API_URL}/refresh`, {}, {
            headers: { Authorization: `Bearer ${token}` },
          });
          localStorage.setItem('sessionToken', response.data.access_token);
        } catch (error) {
          console.error('Error refreshing token:', error);
          setIsAuthenticated(false);
          localStorage.removeItem('sessionToken');
        }
      }
    }, TOKEN_CHECK_INTERVAL);

    return () => {
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keydown', resetInactivityTimer);
      window.removeEventListener('click', resetInactivityTimer);
      clearInterval(checkInactivityAndToken);
    };
  }, [lastActivityTime]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchSubscriptions();
      fetchDropdownData();
    }
  }, [isAuthenticated, fetchSubscriptions, fetchDropdownData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    
    // Reset error if the input is valid
    if (name === 'emails' && value) {
      setErrors((prevErrors) => ({ ...prevErrors, emails: '' })); // Clear email error if valid input
    }
  };

  const handleTenantIDChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, tenant_id: value });

    // Clear any error when the user starts typing
    if (errors.tenant_id) {
      setErrors((prevErrors) => ({ ...prevErrors, tenant_id: '' }));
    }

    if (value === '') {
      resetForm(); // Call reset function if tenant ID is cleared
      resetModalData(); // Reset modal data when tenant_id changes
    }
  };

  

  const validate = () => {
    let formErrors = {};
    if (!formData.tenant_id) formErrors.tenant_id = "Tenant ID is required";
    if (!formData.emails) formErrors.emails = "Email is required";
    if (formData.emails && !/\S+@\S+\.\S+/.test(formData.emails)) formErrors.emails = "Email is invalid";
    if (!formData.report_type) formErrors.report_type = "Report Type is required"; // Validate report_type

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Returns true if no errors
  };

  // useEffect to validate form fields on load
  useEffect(() => {
    validate(); // Run validation on form load
  }, []); // Empty dependency array means this runs once when the component mounts

  // useEffect to validate whenever formData changes
  useEffect(() => {
    validate(); // Run validation whenever formData changes
  }, [formData]); // Dependencies include formData


    const handleShowConfirmModal = (title, message, action) => {
      setConfirmModalTitle(title);
      setConfirmModalMessage(message);
      setConfirmAction(() => action); // Save the action that will be triggered on confirmation
      setShowConfirmModal(true);
    };
    
    const handleConfirm = () => {
      confirmAction(); // Execute the saved action
      setShowConfirmModal(false); // Close the modal
    };
  


    const handleSubmit = async (event) => {
    //  event.preventDefault(); // Prevent form submission
    
      if (!validate()) return; // Validate form; if errors exist, exit early
    
      const confirmMessage = isEdit
        ? "Are you sure you want to update this subscription?"
        : "Are you sure you want to add this new subscription?";
    
      // Show custom confirmation modal instead of window.confirm
      handleShowConfirmModal(
        isEdit ? "Update Subscription" : "Add Subscription", // Modal title
        confirmMessage, // Confirmation message
        async () => { // Action to perform after confirmation
          const reportFrequencyId = 1; // Default value for report_frequency
          const reportTypeId = dropdownData.report_types.find(rt => rt.name === formData.report_type)?.id;
          const subscriptionPeriodId = 3; // Default value for subscription_period
    
          const dataToSubmit = {
            tenant_id: formData.tenant_id,
            tenant_name: formData.tenant_name,
            emails: formData.emails,
            report_frequency_id: reportFrequencyId,
            report_type_id: reportTypeId,
            subscription_period_id: subscriptionPeriodId,
            active: formData.active, // Set active to true or false
            first_name: formData.first_name,
            last_name: formData.last_name,
            user_id: formData.user_id
          };
    
          const token = localStorage.getItem('sessionToken');
          setIsAdding(true); // Set adding state to true
    
          try {
            if (isEdit) {
              setIsUpdating(true); // Set updating state to true
              await axios.put(`${API_URL}/subscriptions/${formData.id}`, dataToSubmit, {
                headers: { Authorization: `Bearer ${token}` },
              });
              showToast('Subscription updated successfully!', 'success', 'Success');
            } else {
              await axios.post(`${API_URL}/subscriptions`, dataToSubmit, {
                headers: { Authorization: `Bearer ${token}` },
              });
              showToast('Subscription added successfully!', 'success', 'Success');
            }
    
            // Fetch subscriptions again to refresh the list
            await fetchSubscriptions(); // Fetch subscriptions to get the latest data
            resetForm();
          } catch (error) {
            console.error('Error saving subscription:', error);
            showToast('Error saving subscription', 'danger', 'Error');
          } finally {
            setIsAdding(false); // Reset adding state
            setIsUpdating(false); // Reset updating state
          }
        }
      );
    };
    
  // Custom function to handle submit button click
  const handleButtonClick = (event) => {
    handleSubmit(); // Call handleSubmit after confirmation
  };
  
  const handleDelete = async (id) => {
      if (!window.confirm("Are you sure you want to delete this subscription?")) {
          return;
      }
  
      setLoading(true); // Start loading
      showToast('Deleting subscription...', 'info', 'Please wait'); // Show toast when deleting starts
  
      const token = localStorage.getItem('sessionToken');
      try {
          await axios.delete(`${API_URL}/subscriptions/${id}`, {
              headers: { Authorization: `Bearer ${token}` },
          });
          fetchSubscriptions(); // Refresh subscriptions after deletion
          showToast('Subscription deleted successfully!', 'success', 'Deleted'); // Show success toast
      } catch (error) {
          console.error('Error deleting subscription:', error);
          showToast('Error deleting subscription', 'danger', 'Error'); // Show error toast
      } finally {
          setLoading(false); // Stop loading
      }
  };

  const handleEdit = (subscription) => {
    setFormData({
      id: subscription.id,
      tenant_id: subscription.tenant_id,
      tenant_name: subscription.tenant_name,
      emails: subscription.emails,
      report_frequency: 1, // Default value for report frequency
      report_type: subscription.report_type || '',
      subscription_period: 3, // Default value for subscription period
      active: subscription.active,
      first_name: subscription.first_name,
      last_name: subscription.last_name,
      user_id: subscription.user_id
    });
    setIsEdit(true);
  };

  const handleCancelEdit = () => {
    resetForm();
  };

  const resetForm = () => {
    setFormData({
      tenant_id: '',
      tenant_name: '',
      emails: '',
      report_frequency: 1, // Default value for report frequency
      report_type: '',
      subscription_period: 3, // Default value for subscription period
      active: false,
      first_name: '',
      last_name: '',
      user_id: ''
    });
    setIsEdit(false);
    setErrors({}); // Reset errors when the form is reset
    setSearchTerm(''); // Reset search term if needed
    setTenants([]); // Clear any tenant search results
  };

  const handleSelect = (field, value) => {
    setFormData({ ...formData, [field]: value });
    setShowDropdown({ ...showDropdown, [field]: false });
    
    // Clear any existing error for this field
    setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
  };

  const showToast = (message, type, header) => {
    setToast({ visible: true, message, type, header });
    setTimeout(() => {
      setToast({ visible: false, message: '', type: '', header: '' });
    }, 3000);
  };

  // Handle the table header search for subscriptions
  const filteredSubscriptions = subscriptions.filter(subscription => {
    const tenantIdMatch = subscription.tenant_id?.toString().includes(tenantIdSearchTerm);
    const tenantNameMatch = (subscription.tenant_name?.toLowerCase() || "").includes(tenantNameSearchTerm.toLowerCase());
    const emailMatch = (subscription.emails?.toLowerCase() || "").includes(emailSearchTerm.toLowerCase());
    const firstNameMatch = (subscription.first_name?.toLowerCase() || "").includes(firstNameSearchTerm.toLowerCase());
    const lastNameMatch = (subscription.last_name?.toLowerCase() || "").includes(lastNameSearchTerm.toLowerCase());
    const reportTypeMatch = (subscription.report_type?.toLowerCase() || "").includes(reportTypeSearchTerm.toLowerCase());
    const activeMatch = (subscription.active ? 'Yes' : 'No').toLowerCase().includes(activeSearchTerm.toLowerCase());
    
    return tenantIdMatch && tenantNameMatch && emailMatch &&firstNameMatch && lastNameMatch && reportTypeMatch && activeMatch; // All search conditions must match
  });
  
  const handleTenantIdSearch = (e) => {
    setTenantIdSearchTerm(e.target.value);
  };

  const handleTenantNameSearch = (e) => {
    setTenantNameSearchTerm(e.target.value);
  };

  const handleFirstNameSearch = (e) => {
    setFirstNameSearchTerm(e.target.value);
  };

  const handleLastNameSearch = (e) => {
    setLastNameSearchTerm(e.target.value);
  };
  
  const handleEmailSearch = (e) => {
    setEmailSearchTerm(e.target.value);
  };

  const handleActiveSearch = (e) => {
    setActiveSearchTerm(e.target.value); // Set the active search term
  };

  const resetModalData = () => {
    setEmailSearchTerm(''); // Reset email search term
    setTenants([]); // Clear the tenant list
    setLoadingEmails(false); // Reset loading state for emails
    setShowEmailModal(false); // Close email modal if it was open
    setShowTenantModal(false); // Close tenant modal if it was open
  };

  if (!isAuthenticated) {
    return <Login onLoginSuccess={handleLoginSuccess} />;
  }


  return (
    <div className="container mt-1" ref={dropdownRef}>
      <h3 className="text-center mb-1">Transactional Reports Subscription Management</h3>

      {/* Custom Modal */}
      <CustomModal show={showModal} handleClose={handleCloseModal} title={modalTitle} body={modalBody} />

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
        <Modal.Header>
          <Modal.Title>{confirmModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmModalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleConfirm}>Confirm</Button>
          <Button variant="secondary" onClick={handleCloseConfirmModal}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      {/* Toast Notification */}
      {toast.visible && (
        <div className="toast-container position-fixed bottom-0 start-50 translate-middle-x p-3" style={{ zIndex: 1055 }}>
          <div className={`toast show text-white bg-${toast.type}`} role="alert">
            <div className="toast-header">
              <strong className="me-auto">{toast.header}</strong>
              <button type="button" className="btn-close" onClick={() => setToast({ ...toast, visible: false })}></button>
            </div>
            <div className="toast-body">{toast.message}</div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-12 mb-2">
          <div className="table-responsive" style={{ maxHeight: '400px', border: '4px solid #007bff' }}>
            <form onSubmit={handleSubmit} className="border p-3 shadow-sm" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', color: '#333' }}>
              
              {/* Tenant ID */}
              <div className="row mb-3">
                <div className="col-sm-3 text-center">
                  <label>Tenant ID</label>
                </div>
                <div className="col-sm-6">
                  <div className="input-group">
                    <input
                      type="number"
                      className={`form-control ${errors.tenant_id ? 'is-invalid' : ''}`}
                      name="tenant_id"
                      value={formData.tenant_id}
                      onChange={handleTenantIDChange}
                      disabled={isEdit || loading}
                      placeholder="Enter Tenant ID"
                    />
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => {
                        setShowTenantModal(true); // Show the modal
                        if (formData.tenant_id) {
                          fetchTenants(null, formData.tenant_id); // Trigger search with tenant_id
                        }
                      }}
                      disabled={isEdit || loading}
                    >
                      <FaSearch />
                    </button>
                  </div>
                  {errors.tenant_id && <div className="invalid-feedback">{errors.tenant_id}</div>}
                </div>
              </div>

              {/* Modal for Tenant Search */}
              {showTenantModal && (
                <div className="modal fade show" style={{ display: 'block', zIndex: 1050 }} tabIndex="-1">
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content" style={{ backgroundColor: '#f8f9fa', borderRadius: '0.5rem', border: '1px solid #dee2e6' }}>
                      <div className="modal-header" style={{ backgroundColor: '#007bff', color: 'white' }}>
                        <h5 className="modal-title">Search Tenant</h5>
                        <button type="button" className="btn-close" onClick={() => setShowTenantModal(false)}></button>
                      </div>
                      <div className="modal-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter tenant name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSearchClick(e); // Invoke search on Enter key press
                              }
                            }}
                          />
                        </div>
                        <button className="btn btn-primary" onClick={handleSearchClick} disabled={loadingTenants || (!searchTerm && !formData.tenant_id)}>
                          {loadingTenants ? 'Searching...' : 'Search'}
                        </button>

                        {/* Show loading indicator or tenant list */}
                        {loadingTenants ? (
                          <div className="text-center mt-3">Searching...</div>
                        ) : (
                          <ul className="list-group mt-3">
                            {tenants.map((tenant) => (
                              <li key={tenant.id} className="list-group-item list-group-item-action" onClick={() => handleTenantSelect(tenant)}>
                                {tenant.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setShowTenantModal(false)}>Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Tenant Name */}
              <div className="row mb-3">
                <div className="col-sm-3 text-center">
                  <label>Tenant Name</label>
                </div>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className={`form-control ${errors.tenant_name ? 'is-invalid' : ''}`}
                    name="tenant_name"
                    value={formData.tenant_name}
                    onChange={handleInputChange}
                    disabled={true}
                  />
                  {errors.tenant_name && <div className="invalid-feedback">{errors.tenant_name}</div>}
                </div>
              </div>

              {/* Email */}
              <div className="row mb-3">
                <div className="col-sm-3 text-center">
                  <label>Email</label>
                </div>
                <div className="col-sm-9">
                  <div className="input-group">
                    <input
                      type="text"
                      className={`form-control ${errors.emails ? 'is-invalid' : ''}`}
                      name="emails"
                      value={formData.emails}
                      onChange={handleInputChange}
                      disabled={loading}
                      readOnly
                    />
                    <button 
                      className="btn btn-secondary" 
                      type="button"
                      onClick={() => {
                        if (!formData.tenant_id) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            tenant_id: "Tenant ID is required to search emails."
                          }));
                          return;
                        }
                        handleEmailSearchClick();
                      }} 
                      disabled={!formData.tenant_id || loading}
                    >
                      <FaSearch />
                    </button>
                  </div>
                  {errors.emails && <div className="invalid-feedback">{errors.emails}</div>}
                  {errors.tenant_id && <div className="text-danger">{errors.tenant_id}</div>} {/* Error message for tenant_id */}
                </div>
              </div>

        
               
             {/* Email Search Modal */}
            {showEmailModal && (
              <div className="modal fade show" style={{ display: 'block', zIndex: 1050 }} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content" style={{ backgroundColor: '#f8f9fa', borderRadius: '0.5rem', border: '1px solid #dee2e6' }}>
                    <div className="modal-header" style={{ backgroundColor: '#007bff', color: 'white' }}>
                      <h5 className="modal-title">Search Email</h5>
                      <button type="button" className="btn-close" onClick={() => setShowEmailModal(false)}></button>
                    </div>
                    <div className="modal-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      
                      {/* Show loading spinner if emails are being fetched */}
                      {loadingEmails ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                          <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <table className="table table-bordered mt-3">
                          <thead className="table-header" style={{ position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 2 }}>
                            <tr>
                              <th style={{ position: 'relative' }}>
                                Name
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder="Search by name"
                                  value={modalEmailSearchTerm}
                                  onChange={(e) => setModalEmailSearchTerm(e.target.value)}
                                  style={{ zIndex: 1 }}
                                />
                              </th>
                              <th style={{ position: 'relative' }}>
                                Email
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder="Search by email"
                                  onChange={(e) => setModalEmailSearchTerm(e.target.value)}
                                  style={{ zIndex: 1 }}
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {emails
                              .filter(email =>
                                (email.first_name + ' ' + email.last_name).toLowerCase().includes(modalEmailSearchTerm.toLowerCase()) ||
                                email.email.toLowerCase().includes(modalEmailSearchTerm.toLowerCase())
                              )
                              .map(email => (
                                <tr key={email.email} className="table-row" onClick={() => handleEmailSelect(email)} style={{ cursor: 'pointer' }}>
                                  <td>{email.first_name + ' ' + email.last_name}</td>
                                  <td>{email.email}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                      
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={() => setShowEmailModal(false)}>Close</button>
                    </div>
                  </div>
                </div>
              </div>
            )}


              {/* Name */}
              <div className="row mb-3">
                <div className="col-sm-3 text-center">
                  <label>Name</label>
                </div>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className={`form-control ${errors.tenant_name ? 'is-invalid' : ''}`}
                    name="tenant_name"
                    value={formData.first_name + ' ' + formData.last_name}
                    onChange={handleInputChange}
                    disabled={true}
                    readOnly
                  />
                  {errors.tenant_name && <div className="invalid-feedback">{errors.tenant_name}</div>}
                </div>
              </div>

              {/* Report Type */}
              <div className="row mb-3">
                <div className="col-sm-3 text-center">
                  <label>Report Type</label>
                </div>
                <div className="col-sm-9">
                  {dropdownData.report_types.length ? (
                    <input
                      type="text"
                      className={`form-control ${errors.report_type ? 'is-invalid' : ''}`}
                      name="report_type"
                      value={formData.report_type}
                      onClick={() => setShowDropdown({
                        report_frequency: false,
                        report_type: !showDropdown.report_type,
                        subscription_period: false,
                      })}
                      readOnly
                    />
                  ) : (
                    <p className="text-muted">Loading Report Types...</p>
                  )}
                  {showDropdown.report_type && (
                    <div className="dropdown-menu show">
                      {dropdownData.report_types.map((rt) => (
                        <button key={rt.id} className="dropdown-item" onClick={() => handleSelect('report_type', rt.name)}>
                          {rt.name}
                        </button>
                      ))}
                    </div>
                  )}
                  {errors.report_type && <div className="invalid-feedback">{errors.report_type}</div>}
                </div>
              </div>

              {/* Active Subscription Checkbox */}
              {isEdit && (  
                <div className="row mb-3">
                  <div className="col-sm-3 text-center">
                    <label>Active Subscription</label>
                  </div>
                  <div className="col-sm-9">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="active"
                      checked={formData.active}  
                      onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
                    />
                    <label className="form-check-label" style={{ marginLeft: '10px' }}>
                      <strong>{formData.active ? 'Yes' : 'No'}</strong>
                    </label>
                  </div>
                </div>
              )}

              {/* Submit and Cancel Button */}
              <div className="mb-2 text-center">
                  <button 
                    type="button" 
                    className="btn btn-primary me-2" 
                    disabled={isAdding || isUpdating || isDeleting} // Disable if any action is in progress
                    onClick={handleButtonClick} 
                  >
                    {isEdit ? (isUpdating ? 'Updating...' : 'Update Subscription') : (isAdding ? 'Adding...' : 'Add Subscription')}
                  </button>
                  {isEdit && (
                    <button 
                      type="button" 
                      className="btn btn-secondary" 
                      onClick={handleCancelEdit} 
                      disabled={isAdding || isUpdating || isDeleting}
                    >
                      <FaTimes /> Cancel
                    </button>
                  )}
              </div>

            </form>
          </div>
        </div>
      </div> {/* Subscription Form */}

      <div className="row" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
  {/* Subscription List Section */}
<div className="col-md-12" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
  <div className="table-responsive" style={{ height: 'calc(100vh - 150px)', border: '4px solid #007bff', marginBottom: '10px', overflowY: 'auto' }}>
    
    {/* Show loading spinner if subscriptions are being fetched */}
    {loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ) : (
      <table className="table table-bordered mt-3" style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead className="table-header" style={{ position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 2 }}>
          <tr>
            <th style={{ maxWidth: '100px' }}>
              Tenant ID
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search Tenant ID"
                value={tenantIdSearchTerm}
                onChange={handleTenantIdSearch}
              />
            </th>
            <th style={{ maxWidth: '150px' }}>
              Tenant Name
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search Tenant Name"
                value={tenantNameSearchTerm}
                onChange={handleTenantNameSearch}
              />
            </th>
            <th style={{ maxWidth: '150px' }}>
              First Name
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search First Name"
                value={firstNameSearchTerm}
                onChange={handleFirstNameSearch}
              />
            </th>
            <th style={{ maxWidth: '150px' }}>
              Last Name
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search Last Name"
                value={lastNameSearchTerm}
                onChange={handleLastNameSearch}
              />
            </th>
            <th style={{ maxWidth: '150px' }}>
              Email
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search Email"
                value={emailSearchTerm}
                onChange={handleEmailSearch}
              />
            </th>
            <th style={{ maxWidth: '150px' }}>
              Report Type
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search Report Type"
                value={reportTypeSearchTerm}
                onChange={handleReportTypeSearch}
              />
            </th>
            <th style={{ maxWidth: '150px' }}>
              Subscription Active
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search Active"
                value={activeSearchTerm}
                onChange={handleActiveSearch} 
              />
            </th>
            <th style={{ maxWidth: '100px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredSubscriptions.map((subscription) => (
            <tr key={subscription.id} style={{ cursor: 'pointer' }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f1f1f1'} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}>
              <td style={{ textAlign: 'center' }}>{subscription.tenant_id}</td>
              <td style={{ textAlign: 'center' }}>{subscription.tenant_name}</td>
              <td style={{ textAlign: 'center' }}>{subscription.first_name}</td>
              <td style={{ textAlign: 'center' }}>{subscription.last_name}</td>
              <td style={{ textAlign: 'center' }}>{subscription.emails}</td>
              <td style={{ textAlign: 'center' }}>{subscription.report_type}</td>
              <td style={{ textAlign: 'center' }}>{subscription.active ? 'Yes' : 'No'}</td>
              <td style={{ textAlign: 'center' }}>
                <button className="btn btn-link" onClick={() => handleEdit(subscription)} disabled={loading}>
                  <FaEdit className="text-primary" />
                </button>
                <button className="btn btn-link" onClick={() => handleDelete(subscription.id)} disabled={deleteLoading}>
                  <FaTrash className="text-danger" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
    
  </div>
</div>

</div>

    </div>
  );
}

export default App;
